(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

var _translation_fields = require("./forms/translation_fields");

var _translation_fields2 = _interopRequireDefault(_translation_fields);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

$(document).ready(function () {
    "use strict";

    $(document).foundation();
});

window.preventDoubleClick = function (form, buttonName) {
    "use strict";

    if (form.hasOwnProperty(buttonName)) form[buttonName].disabled = true;

    return true;
};

window.TranslationFields = _translation_fields2.default;

},{"./forms/translation_fields":3}],2:[function(require,module,exports){
"use strict";

$(document).ready(function () {
    var $fileUpload = $('.file-upload');
    var $input = $fileUpload.find('input[type="file"]');
    var $errorMessage = $fileUpload.find('.file-upload-error');

    $fileUpload.addClass('has-advanced-upload');

    var droppedFiles = false;

    $fileUpload.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
        "use strict";

        e.preventDefault();
        e.stopPropagation();
    }).on('dragover dragenter', function () {
        $fileUpload.addClass('is-dragover');
    }).on('dragleave dragend drop', function () {
        $fileUpload.removeClass('is-dragover');
    }).on('drop', function (e) {
        droppedFiles = e.originalEvent.dataTransfer.files;
        $fileUpload.trigger('submit');
    });

    $input.on('change', function (e) {
        $fileUpload.trigger('submit');
    });

    $fileUpload.on('submit', function (e) {
        if ($fileUpload.hasClass('is-uploading')) return false;
        $fileUpload.addClass('is-uploading').removeClass('is-error');

        e.preventDefault();

        var ajaxData = new FormData($fileUpload.get(0));
        if (droppedFiles) {
            $.each(droppedFiles, function (i, file) {
                ajaxData.append($input.attr('name'), file);
            });
        }

        $.ajax({
            url: $fileUpload.attr('action'),
            type: $fileUpload.attr('method'),
            data: ajaxData,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            complete: function complete(data) {
                $fileUpload.removeClass('is-uploading');
            },
            success: function success(data) {
                $fileUpload.addClass(data.success == true ? 'is-success' : 'is-error');
                if (!data.success) $errorMessage.text(data.error);
                console.log(data.uploaded);
            },
            error: function error() {
                // Log the error, show an alert, whatever works for you
            }
        });
    });
});

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TranslationFields = function () {
    function TranslationFields() {
        _classCallCheck(this, TranslationFields);
    }

    _createClass(TranslationFields, [{
        key: 'initialize',
        value: function initialize() {
            $('[data-dynamic-form-localized-field-container]').each(function (containerKey, containerValue) {
                var localeLabels = {};
                var container = $(containerValue);

                // don't rerun
                if (container.data('dynamic-form-localized-field-container') === 'true') {
                    return;
                }

                container.find('[data-dynamic-form-localized-field-label]').each(function (labelKey, labelValue) {
                    var label = $(labelValue);
                    var locale = label.data('dynamic-form-localized-field-label');
                    if (locale !== undefined) {
                        localeLabels[locale] = label;
                    }
                });

                container.find('[data-dynamic-form-localized-field-input]').each(function (inputKey, inputValue) {
                    var input = $(inputValue);
                    var value = input.val();
                    var locale = input.data('dynamic-form-localized-field-input');
                    var label = localeLabels[locale];

                    input.on('input', function () {
                        TranslationFields.translationFieldChanged(input, label);
                    });

                    label.on('click', function () {
                        TranslationFields.translationLabelClicked(container, input, label);
                    });

                    if (value === undefined || value === '') {
                        TranslationFields.translationFieldEmpty(label);
                    } else {
                        TranslationFields.translationFieldFilled(label);
                    }

                    if (inputKey === 0) {
                        input.show();
                        label.addClass('active');
                    } else {
                        input.hide();
                    }
                });

                // set true to say that the file has already been localized
                container.attr('dynamic-form-localized-field-container', 'true');
            });
        }
    }], [{
        key: 'translationFieldChanged',
        value: function translationFieldChanged(input, label) {
            if (input === undefined || label === undefined) {
                return;
            }

            if (input.val() === '') {
                TranslationFields.translationFieldEmpty(label);
            } else {
                TranslationFields.translationFieldFilled(label);
            }
        }
    }, {
        key: 'translationLabelClicked',
        value: function translationLabelClicked(container, input, label) {
            container.find('li a').removeClass('active');
            label.addClass('active');

            container.find('input').each(function () {
                $(this).hide();
            });

            input.show();
        }
    }, {
        key: 'translationFieldEmpty',
        value: function translationFieldEmpty(label) {
            label.removeClass('filled');
            label.addClass('empty');
        }
    }, {
        key: 'translationFieldFilled',
        value: function translationFieldFilled(label) {
            label.addClass('filled');
            label.removeClass('empty');
        }
    }]);

    return TranslationFields;
}();

exports.default = TranslationFields;


window.TranslationFields = TranslationFields;

},{}]},{},[1,2]);
